import React from "react";
import Section from "./Section";

const Philosophy = () => (
  <Section title="Philosophy">
    <p>
      I think what's important for a software engineer in the industry is to be
      curious and always learning. I honestly think that what's more important
      than technical skills and knowledge of the craft (though these things are
      important), is the ability to be humble, adapt quickly, and make smart
      decisions.
    </p>
  </Section>
);

export default Philosophy;
