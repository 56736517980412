const colors = {
  white: "#fff",
  black: "#000",
  primary: "#EF5353",
  secondary: "#31CECB",
  accent: "#F7F052",
  lightDark: "#01212B",
  beige: "#F5F5DC",
  ivory: "#FFFDF4",
  mint: "#F2FCF9",
  aqua: "#66cdaa",
  comfortRed: "#ef6f6c",
  warmBlack: "#02394A",
  comfortNavy: "#465775",
  brightAqua: "#56e39f",
  red: "#ed1c24",
  blue: "#235789",
  yellow: "#f1d302",
  reactBlue: "#68C3D4",
  nodeGreen: "#4B9600",
  androidGreen: "#BED868"
};

export default colors;
